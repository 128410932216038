
let config={
    // appName:'FunStory',
    // // appLogo:require('@/assets/images/logo.png').default,
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1599044136',
    // contactEmail:'funstory.cs@outlook.com',
    // copyright:'FunStory',
    // title:'FunStory',
    // websiteUrl:'funstorys.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1599044136',
    //     'appstore:bundle_id':'swbro.BookManager',
    //     'appstore:store_id':'1599044136'
    // }


    // appName:'Mobooks',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/za/app/id1615497181',
    // contactEmail:'mobooks.cs@outlook.com',
    // copyright:'Mobooks',
    // title:'Mobooks',
    // websiteUrl:'shepherdnovels.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/za/app/id1615497181',
    //     'appstore:bundle_id':'com.mobooks.novel',
    //     'appstore:store_id':'1615497181'
    // }

    // appName:'PandaReads',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/app/id1626427449',
    // contactEmail:'pandareads.cs@outlook.com',
    // copyright:'PandaReads',
    // title:'PandaReads',
    // websiteUrl:'pandareads.ficfunapp.com'


    // appName:'whatsreads',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1629004177',
    // contactEmail:'whatsread.cs@outlook.com',
    // copyright:'whatsreads',
    // title:'whatsreads',
    // websiteUrl:'whatsreads.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1629004177',
    //     'appstore:bundle_id':'com.webnovel.whatsread',
    //     'appstore:store_id':'1629004177'
    // }

    // appName:'Funtel',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1597216091',
    // contactEmail:'funtel.cs@outlook.com',
    // copyright:'Funtel',
    // title:'Funtel',
    // websiteUrl:'funtelnovel.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1597216091',
    //     'appstore:bundle_id':'funfic.novmic.com',
    //     'appstore:store_id':'1597216091'
    // },

    // appName:'Pocket eReader',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1629561758',
    // contactEmail:'pocket_ereader@163.com',
    // copyright:'Pocket eReader',
    // title:'Pocket eReader',
    // websiteUrl:'ereaderlab.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1629561758',
    //     'appstore:bundle_id':'com.pocket.ereader',
    //     'appstore:store_id':'1629561758'
    // },


    appName:'eReader',
    googlePlayUrl:null,
    appStoreUrl:'https://apps.apple.com/us/app/id1580895095',
    contactEmail:'ereader2022@163.com',
    copyright:'eReader',
    title:'eReader',
    websiteUrl:'ereaderlab.com',
    headMeta:{
        'appstore:developer_url':'https://apps.apple.com/us/app/id1580895095',
        'appstore:bundle_id':'com.kuaidu.reader',
        'appstore:store_id':'1580895095'
    },


    // appName:'Readfic',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1612039188',
    // contactEmail:'readfic.cs@outlook.com',
    // copyright:'Readfic',
    // title:'Readfic',
    // websiteUrl:'readfic.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1612039188',
    //     'appstore:bundle_id':'com.readfic.novel',
    //     'appstore:store_id':'1612039188'
    // },

    // appName:'Sweet',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1630347934',
    // contactEmail:'sweetfic.cs@outlook.com',
    // copyright:'Sweet',
    // title:'Sweet',
    // websiteUrl:'sweetfic.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1630347934',
    //     'appstore:bundle_id':'com.sweet.novel',
    //     'appstore:store_id':'1630347934'
    // },

    // appName:'TOPICKS',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1633000700',
    // contactEmail:'topicks.cs@outlook.com',
    // copyright:'TOPICKS',
    // title:'TOPICKS',
    // websiteUrl:'topicksapp.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1633000700',
    //     'appstore:bundle_id':'com.topicks.novel',
    //     'appstore:store_id':'1633000700'
    // },

    // appName:'PopFic',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1596541746',
    // contactEmail:'popfic2021@163.com',
    // copyright:'PopFic',
    // title:'PopFic',
    // websiteUrl:'thepopfic.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1596541746',
    //     'appstore:bundle_id':'com.cy.popfic.appstore',
    //     'appstore:store_id':'1596541746'
    // }


    // appName:'Pocket eBooks',
    // googlePlayUrl:null,
    // appStoreUrl:'https://apps.apple.com/us/app/id1635682532',
    // contactEmail:'pocket_ebooks@163.com',
    // copyright:'Pocket eBooks',
    // title:'Pocket eBooks',
    // websiteUrl:'pocketebooks.com',
    // headMeta:{
    //     'appstore:developer_url':'https://apps.apple.com/us/app/id1635682532',
    //     'appstore:bundle_id':'com.pocket.ebooks',
    //     'appstore:store_id':'1635682532'
    // }
}

export default config;
